import Vue from "vue";
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueSocialauth from 'vue-social-auth';
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";

import VueFileAgent from 'vue-file-agent';
import router from "./router";
import store from "./store";
import App from "./App.vue";


// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
import mixin from '@/mixins';

// require
require("@/global-components");

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(VueFileAgent);

// Composition API
Vue.use(VueCompositionAPI);


// Social auth
Vue.use(VueAxios, axios);
Vue.use(VueSocialauth, {
    providers: {
        google: {
            clientId: process.env.VUE_APP_GOOGLE_LOGIN_CLIENT_ID,
            redirectUri: `${process.env.VUE_APP_URL}/login`
        },
    }
});

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

require("@/global-functions");

Vue.config.productionTip = false;
store.dispatch("auth/me").then(() => {
    new Vue({
        router,
        store,
        render: h => h(App),
    }).$mount("#app");
});

Vue.mixin(mixin);
