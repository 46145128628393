<template>
  <content-loader
    :width="476"
    :height="70"
    :speed="2"
    primary-color="#f3f3f3"
    secondary-color="#ecebeb"
  >
    <rect x="50" y="6" rx="4" ry="4" width="343" height="20" /> 
    <rect x="8" y="6" rx="4" ry="4" width="35" height="20" /> 
    <rect x="50" y="40" rx="4" ry="4" width="343" height="20" /> 
    <rect x="8" y="39" rx="4" ry="4" width="35" height="20" />
  </content-loader>
</template>

<script>
import { ContentLoader } from "vue-content-loader";

export default {
    name: "MinimumTaskList",
    components: { ContentLoader },
};
</script>