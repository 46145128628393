import Vue from "vue";
import VueRouter from "vue-router";
import routeList from "./route";
import store from "../store";
// import middlewarePipeline from "./middlewarePipeline";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: routeList,
});

// authenticated middleware
router.beforeEach((to, from, next) => {
    if (! to.meta.middleware)
        return next();

    const {middleware} = to.meta;
    let nextRoute = '';

    // let's start the middleware from the first then pipe it rest
    middleware.every(layer => {
        const routeName = layer({
            to,
            from,
            next,
            store
        });

        if (routeName && nextRoute === '') {
            nextRoute = routeName;
            return false;
        }

        return true;
    });

    if (nextRoute){
        return next({name : nextRoute});
    }

    return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById("loading-bg");
    if (appLoading) {
        appLoading.style.display = "none";
    }
});

export default router;
