<template>
    <button
        ref="button"
        :style="style"
        :width="width"
        :height="height"
        :class="classList"
        @click="to ? $router.push(to) : ''"
    >
        <span ref="text" class="text d-flex flex-grow-1 justify-content-center">
            <slot></slot>
        </span>

        <span ref="icon">
            <vector
                data-type="icons"
                :data-name="iconName"
                :data-style="iconStyle"
                :data-width="iconWidth"
                :data-height="iconHeight"
                :data-class="getClasses()"
            ></vector>
        </span>
    </button>
</template>

<script>
import Vector from "./Vector.vue";

export default {
    name: "Action",

    components: {
        Vector,
    },

    props: {
        // button size: small|medium|large
        dataSize: {
            type: String,
            default: "medium",
            required: false,
        },

        // button type: primary|secondary|success etc
        dataType: {
            type: String,
            default: "primary",
            required: false,
        },

        // the icon name for the button
        dataIconName: {
            type: String,
            default: "",
            required: false,
        },

        // the classes for the button
        dataClass: {
            type: String,
            default: "",
            required: false,
        },

        // the route name object to redirect on click
        dataTo: {
            type: Object,
            default: () => {},
            required: false,
        },

        dataWidth: {
            type: String,
            default: "",
            required: false,
        },

        dataHeight: {
            type: String,
            default: "",
            required: false,
        },

        dataStyle: {
            type: String,
            default: "",
            required: false,
        },

        dataIconWidth: {
            type: String,
            default: "21px",
            required: false,
        },

        dataIconHeight: {
            type: String,
            default: "21px",
            required: false,
        },

        dataIconClass: {
            type: String,
            default: "",
            required: false,
        },

        dataIconStyle: {
            type: String,
            default: "",
            required: false,
        },
    },

    data() {
        return {
            to: this.dataTo,
            size: this.dataSize,
            type: this.dataType,
            width: this.dataWidth,
            style: this.dataStyle,
            height: this.dataHeight,
            iconName: this.dataIconName,
            iconStyle: this.dataIconStyle,
            iconClass: this.dataIconClass,
            iconWidth: this.dataIconWidth,
            iconHeight: this.dataIconHeight,
        };
    },

    computed: {
        classList() {
            const defaultClass = `d-flex justify-content-center align-items-center btn btn-${this.type}`;
            return `${defaultClass} ${this.dataClass}`;
        },
    },

    mounted() {
        // for lage button
        if (this.dataSize === "large" || this.dataSize === "big") {
            this.$refs.button.classList.add("w-100", "p-1.1");
            this.$refs.button.classList.replace(
                "justify-content-around",
                "justify-content-between"
            );
        }
        // for medium button
        else if (this.dataSize === "medium" || this.dataSize === "normal") {
            this.$refs.button.classList.add("normal", "p-1.1");
            this.$refs.text.classList.add("mr-.6");
        }
        // for small button
        else if (this.dataSize === "small") {
            // this.$refs.button.style.padding=".74rem";
            this.$refs.text.classList.add("mr-.6");
            this.$refs.button.classList.add("btn-sm");
        }
        // throw error
        else {
            const message = `The size value: ${this.dataSize} is not valid.`;

            if (process.env.NODE_ENV === "development") {
                // eslint-disable-next-line no-console
                console.error(message);
                // eslint-disable-next-line no-alert
                alert(message);
            }
        }
    },

    methods: {
        getClasses() {
            let resultClass = "";
            resultClass += this.color === "secondary" ? "svg-path-primary" : "";

            if (this.iconClass) resultClass += ` ${this.iconClass}`;

            return resultClass;
        },
    },
};
</script>

<style scoped>
.btn-outline-primary:hover {
    box-shadow: 0 8px 25px -8px rgba(66, 103, 178, 0.2);
}

.btn-sm {
    padding: 0.586rem 1rem !important;
}
</style>