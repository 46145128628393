<template>
    <div :style="`width: ${dataWidth}; height: ${dataHeight}`">
        <component :is="loaderType"></component>
    </div>
</template>

<script>
import {
    ContentLoader,
    FacebookLoader,
    CodeLoader,
    BulletListLoader,
    InstagramLoader,
    ListLoader,
} from "vue-content-loader";

import CartLoader from './customLoaders/CartLoader.vue';
import OrderDetailLoader from './customLoaders/OrderDetail.vue';
import TaskList from './customLoaders/TaskList.vue';
import CardList from './customLoaders/CardList.vue';
import MinimumTaskList from './customLoaders/MinimumTaskList.vue';
import Table from './customLoaders/Table.vue';


export default {
    name: "Loader",

    components: {
        ListLoader,
        CodeLoader,
        ContentLoader,
        FacebookLoader,
        InstagramLoader,
        BulletListLoader,
        CartLoader,
        TaskList,
        OrderDetailLoader,
        MinimumTaskList,
        CardList,
        Table
    },

    props: {
        dataType: {
            type: String,
            required: false,
            default: "FacebookLoader",
        },

        dataWidth: {
            type: String,
            required: false,
            default: ''
        },

        dataHeight: {
            type: String,
            required: false,
            default: ''
        }
    },

    data() {
        return {
            type: this.dataType,
        };
    },

    computed: {
        loaderType() {
            if (this.type === "facebook" || this.type === "Facebook")
                return "FacebookLoader";

            if (this.type === "code" || this.type === "Code")
                return "CodeLoader";

            if (this.type === "list" || this.type === "List")
                return "ListLoader";

            if (this.type === "bullet-list" || this.type === "Bullet")
                return "BulletListLoader";

            if (this.type === "instagram" || this.type === "Instagram")
                return "InstagramLoader";
            
            if(this.type === 'cart' || this.type === 'Cart')
                return "CartLoader";

            if (this.type === 'order-details' || this.type === 'OrderDetails' || this.type === 'OrderDetail') 
                return OrderDetailLoader;

            if (this.type === 'TaskList' || this.type === 'task')
                return TaskList;

            if (this.type === 'MinimumTaskList')
                return MinimumTaskList;

            if (this.type === 'CardList')
                return CardList;

            if (this.type === 'Table')
                return Table;

            return this.type;
        },
    },
};
</script>