<template>
  <content-loader
    :width="460"
    :height="300"
    :speed="2"
    primary-color="#f3f3f3"
    secondary-color="#ecebeb"
  >
    <rect x="10" y="20" rx="5" ry="5" width="110" height="15" /> 
    <rect x="-5" y="150" rx="5" ry="5" width="300" height="5" /> 
    <rect x="152" y="20" rx="5" ry="5" width="130" height="15" /> 
    <rect x="11" y="90" rx="5" ry="5" width="110" height="15" /> 
    <rect x="152" y="90" rx="5" ry="5" width="130" height="15" /> 
    <rect x="11" y="170" rx="5" ry="5" width="110" height="15" /> 
    <rect x="152" y="169" rx="5" ry="5" width="130" height="15" /> 
    <rect x="11" y="120" rx="5" ry="5" width="110" height="15" /> 
    <rect x="152" y="120" rx="5" ry="5" width="130" height="15" /> 
    <rect x="11" y="57" rx="5" ry="5" width="110" height="15" /> 
    <rect x="152" y="57" rx="5" ry="5" width="130" height="15" />
 
  </content-loader>
</template>

<script>
import { ContentLoader } from "vue-content-loader";

export default {
    components: { ContentLoader }
};
</script>
