import { googleLogin, signIn, myInfo, signOut, signUp } from "@/apis/auth";
import { EMAIL_VERIFICATION_ERROR } from '@/constants/errors';

export default {
    namespaced: true,

    state: {
        user: null,

        campaign: null,

        campaignList: null,

        // is login
        authenticated: false,

        verified: false,
    },

    getters: {
        authenticated(state) {
            return state.authenticated;
        },

        user(state) {
            return state.user;
        },

        campaign(state) {
            return state.campaign;
        },

        campaignList(state) {
            return state.campaignList;
        },

        verified(state)
        {
            return state.verified;
        },
    },

    mutations: {
        SET_AUTHENTICATED(state, value) {
            state.authenticated = value;
        },

        SET_USER(state, value) {
            state.user = value;
        },

        SET_CAMPAIGN(state, value) {
            state.campaign = value;
        },

        SET_CAMPAIGN_LIST(state, value) {
            state.campaignList = value;
        },

        ADD_CAMPAIGN(state, value) {
            state.campaignList.push(value);
        },

        UPDATE_VERIFIED_STATUS(state, value)
        {
            state.verified = value;
        }
    },

    actions: {
        async login({ dispatch }, credentials) {
            await signIn(credentials);

            return dispatch("me");
        },

        async logout() {
            await signOut();
        },

        async register( { dispatch },credentials) {
            await signUp(credentials);

            return dispatch("me");
        },


        async me({ commit }) {
            const response = await myInfo().then(data => {
                commit("SET_AUTHENTICATED", true);
                commit("SET_USER", data.user);
                commit("SET_CAMPAIGN", data.campaign);
                commit("SET_CAMPAIGN_LIST", data.campaignList);

                if (data.user.email_verified_at)
                {
                    commit('UPDATE_VERIFIED_STATUS', true);
                }
            })
                .catch(e => {

                    if (e.response && e.response.data.error === EMAIL_VERIFICATION_ERROR)
                    {
                        commit('UPDATE_VERIFIED_STATUS', false);
                    } else {
                        commit("SET_AUTHENTICATED", false);
                        commit("SET_USER", null);
                        commit("SET_CAMPAIGN", null);
                    }

                    return e;
                });

            return response;
        },

        async googleLogin({dispatch}, credentials){
            await googleLogin(credentials);

            return dispatch("me");
        }
    },
};
