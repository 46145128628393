import axios from "./axios";

export const signIn = async credentials => {
    await axios.get("/sanctum/csrf-cookie");
    await axios.post("/login", credentials);
};

export const googleLogin = async credentials => {
    await axios.get("/sanctum/csrf-cookie");
    await axios.post("/api/auth/google-login", credentials);
};

export const myInfo = async () => {
    await axios.get("/sanctum/csrf-cookie");
    const response = await axios.get("/api/startup-data");
    return response.data;
};

export const signOut = async () => {
    const response = await axios.post("/logout");
    return response.data;
};

export const signUp = async data => {
    const response = await axios.post("/register", data);
    return response;
};

export const generateOtp = async () => {
    const response = await axios.get('/api/generate-otp');
    return response;
};

export const verifyOtp = async otp => {
    const response = await axios.post('/api/verify-otp', {
        otp_code: otp
    });
    return response;
};
