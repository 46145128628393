import auth from "@/middlewares/auth";
import guest from "@/middlewares/guest";
import verify from "@/middlewares/verify";
import isAdmin from "@/middlewares/isAdmin";

const defaultMiddlewares = [
    auth,
    verify
];

const list = [

    // companies
    {
        path: '/companies',
        name: 'companies',
        component: () => import ("@/views/components/companies/Index.vue"),
        meta: {
            pageTitle: 'Companies',
            breadcrumb: [
                {
                    text: 'Companies',
                    to: {name: 'companies'},
                    active: true
                },
            ],
            middleware: [
                ...defaultMiddlewares,
            ]
        },
    },

    {
        path: '/',
        name: 'campaigns',
        component: () => import ("@/views/components/campaigns/Index.vue"),
        meta: {
            pageTitle: 'Campaigns',
            breadcrumb: [
                {
                    text: 'Campaigns',
                    to: {name: 'campaigns'},
                    active: true
                },
            ],
            middleware: [
                ...defaultMiddlewares,
            ]
        },
    },

    {
        path: '/companies/leads',
        name: 'company.leads',
        props: true,
        component: () => import ("@/views/components/companies/Index.vue"),
        meta: {
            status : 'leads',
            middleware: [
                ...defaultMiddlewares,
            ]
        },
    },

    {
        path: '/companies/approached',
        name: 'company.approached',
        props: true,
        component: () => import ("@/views/components/companies/Index.vue"),
        meta: {
            status : 'approached',
            middleware: [
                ...defaultMiddlewares,
            ]
        },
    },

    {
        path: '/companies/response-received',
        name: 'company.response-received',
        props: true,
        component: () => import ("@/views/components/companies/Index.vue"),
        meta: {
            status : 'response_received',
            middleware: [
                ...defaultMiddlewares,
            ]
        },
    },

    {
        path: '/companies/acquired',
        name: 'company.acquired',
        props: true,
        component: () => import ("@/views/components/companies/Index.vue"),
        meta: {
            status : 'acquired',
            middleware: [
                ...defaultMiddlewares,
            ]
        },
    },


    {
        path: "/login",
        name: "login",
        component: () => import("@/views/pages/auth/Login.vue"),
        meta: {
            layout: "full",
            middleware: [
                guest
            ]
        },
    },

    {
        path: "/register",
        name: "register",
        component: () => import("@/views/pages/auth/Register.vue"),
        meta: {
            layout: "full",
            guest: true,
            middleware: [
                guest
            ]
        }
    },

    {
        path: "/users",
        name: "users",
        props: true,
        component: () => import("@/views/components/users/Index.vue"),
        meta: {
            middleware: [
                ...defaultMiddlewares,
            ]
        },
    },

    {
        path: "/imports",
        name: "imports",
        props: true,
        component: () => import("@/views/components/companiesImports/Index.vue"),
        meta: {
            middleware: [
                ...defaultMiddlewares,
                isAdmin,
            ]
        },
    },

    {
        path: "/verify-email",
        name: "verifyEmail",
        component: () => import("@/views/pages/auth/EmailVerify.vue"),
        meta: {
            layout: "full",
            middleware: [
                auth
            ]
        }
    },

    {
        path: "/error-404",
        name: "error-404",
        component: () => import("@/views/error/Error404.vue"),
        meta: {
            layout: "full",
        },
    },


    {
        path: "*",
        redirect: "error-404",
    },


];

export default list;
