export default function verify({ store})
{
    if (store.getters['auth/user'] && store.getters['auth/user'].email_verified_at)
    {
        store.commit('auth/UPDATE_VERIFIED_STATUS', true);
    }

    if (! store.getters["auth/verified"])
    {
        return 'verifyEmail';
    }

    return false;
}
