const mixin = {
    methods: {
        getAvatarInitial: fullName => {
            const nameArray = fullName.split(" ");
            return `${nameArray[0][0]}${nameArray[nameArray.length - 1][0]}`;
        },

        formatPrice: price => `$${price.toLocaleString()}`,

        transformDate: date => {
            const dateObj = new Date(date);
            const month = dateObj.toLocaleString("default", { month: "short" });
            const day = dateObj.getDate();
            const year = dateObj.getFullYear();
            return `${day} ${month} ${year}`;
        },

        getAxiosErrorMessage(error)
        {
            if (error.response)
                return error.response.data.message;

            return error.message;
        }
    }
};

export default mixin;