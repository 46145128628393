/* eslint-disable import/prefer-default-export */
export const EMAIL_VERIFICATION_ERROR = 'Your email address is not verified.';

export const NOT_ALLOWED = 'You are not allowed to perform this action.';

export const SERVER_ERROR = 'The server was unable to complete your request. Please try again later.';

// general
export const SOMETHING_WENT_WRONG = 'Something went wrong. Please try again later.';
export const NO_SELECTED_IDS = "Please check any records to download.";

export const NO_CAMPAIGN = "In order to create a user, you must create a campaign.";
