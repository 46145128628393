<template>
    <content-loader
        :width="600"
        :height="130"
        :speed="2"
        primary-color="#f3f3f3"
        secondary-color="#ecebeb"
    >
<rect x="58" y="19" rx="3" ry="3" width="120" height="15" /> 
    <rect x="249" y="19" rx="3" ry="3" width="120" height="15" /> 
    <rect x="11" y="4" rx="3" ry="3" width="570" height="2" /> 
    <rect x="663" y="354" rx="3" ry="3" width="141" height="15" /> 
    <rect x="664" y="415" rx="3" ry="3" width="141" height="15" /> 
    <rect x="664" y="475" rx="3" ry="3" width="141" height="15" /> 
    <rect x="9" y="46" rx="3" ry="3" width="570" height="2" /> 
    <rect x="10" y="125" rx="3" ry="3" width="570" height="2" /> 
    <rect x="422" y="19" rx="3" ry="3" width="120" height="15" /> 
    <rect x="58" y="60" rx="3" ry="3" width="120" height="15" /> 
    <rect x="249" y="60" rx="3" ry="3" width="120" height="15" /> 
    <rect x="422" y="60" rx="3" ry="3" width="120" height="15" /> 
    <rect x="12" y="87" rx="3" ry="3" width="570" height="2" />
    </content-loader>
</template>

<script>
import { ContentLoader } from "vue-content-loader";

export default {
    name: "Table",
    components: { ContentLoader },
};
</script>