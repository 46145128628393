<template>
  <content-loader
    :width="400"
    :height="150"
    :speed="2"
    primary-color="#f3f3f3"
    secondary-color="#ecebeb"
  >
    <rect x="8" y="6" rx="4" ry="4" width="20" height="18" /> 
    <rect x="40" y="6" rx="4" ry="4" width="343" height="18" /> 
    <rect x="40" y="55" rx="4" ry="4" width="343" height="18" /> 
    <rect x="8" y="55" rx="4" ry="4" width="20" height="18" /> 
    <rect x="40" y="104" rx="4" ry="4" width="343" height="18" /> 
    <rect x="8" y="104" rx="4" ry="4" width="20" height="18" />
  </content-loader>
</template>

<script>
import { ContentLoader } from "vue-content-loader";

export default {
    name: 'TaskList',
    components: { ContentLoader }
};
</script>