const getDefaultState = (() => ({
    items: [],
    enstonService: '',
}));

export default {
    namespaced: true,

    state: getDefaultState(),

    getters: {
        getAllData(state)
        {
            return state;
        },

        getItemsCount(state) {
            return state.items.length;
        },

        getItems(state)
        {
            return state.items;
        },

        getEnstonService(state) {
            return state.enstonService;
        },
    },

    mutations: {
        CLEAR(state)
        {
            Object.assign(state, getDefaultState());
        },

        ADD(state, items)
        {
            if (Array.isArray(items))
            {
                items.forEach(item => {
                    // only push if it is not already exist in the store
                    if (
                        state.items.filter(existingItem => existingItem.name === item.name && existingItem.id === item.id).length <= 0
                    )
                        state.items.push(item);
                });

                return;
            }

            if (
                state.items.filter(existingItem => existingItem.name === items.name).length <= 0
            )
                state.items.push(items);
        },

        REMOVE(state, items)
        {
            if (Array.isArray(items))
            {
                items.forEach(item => {
                    state.items.forEach((existingItem, index) => {
                        if (existingItem.name === item.name && existingItem.id === item.id)
                            state.items.splice(index, 1);
                    });
                });

                return;
            }

            state.items.forEach((existingItem, index) => {
                if (existingItem.name === items.name && existingItem.id === items.id)
                    state.items.splice(index, 1);
            });
        },

        UPDATE_ENSTON_SERVICE(state, value) {
            state.enstonService = value;
        },

        UPDATE_PRICE(state, data)
        {
            const fee = state.items.find(item => item.name === data.name);
            fee.price = data.price;
        }
    },

    actions: {},
};
