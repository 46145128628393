<template>
    <content-loader
        :width="300"
        :height="300"
        :speed="2"
        primary-color="#f3f3f3"
        secondary-color="#ecebeb"
    >
        <rect x="4" y="598" rx="0" ry="0" width="294" height="3" />
        <rect x="158" y="596" rx="0" ry="0" width="5" height="3" />
        <rect x="5" y="60" rx="0" ry="0" width="294" height="3" />
        <rect x="94" y="20" rx="0" ry="0" width="129" height="23" />
        <rect x="28" y="91" rx="4" ry="4" width="243" height="31" />
        <rect x="62" y="155" rx="4" ry="4" width="148" height="19" />
        <circle cx="38" cy="164" r="10" />
        <circle cx="39" cy="222" r="10" />
        <circle cx="39" cy="274" r="10" />
        <circle cx="39" cy="329" r="10" />
        <rect x="65" y="213" rx="4" ry="4" width="148" height="19" />
        <rect x="65" y="266" rx="4" ry="4" width="148" height="19" />
        <rect x="65" y="318" rx="4" ry="4" width="148" height="19" />
        <circle cx="39" cy="547" r="10" />
    </content-loader>
</template>

<script>
import { ContentLoader } from "vue-content-loader";

export default {
    name: "CartLoader",
    components: {
        ContentLoader,
    },
};
</script>