const getDefaultState = () => ({
    currentOnboardingPage: '',
    companyName: null,
    companyDomain: {},
    currentUsercompany: 'default',
    companyDomainText: 'Pte. Ltd.',
    financialYearEnd: '',
    primaryBusinessActivity: 0,
    secondaryBusinessActivity: 0,
    capitalCurrency: 0,
    capitalAmount: '',
    totalShare: '',
    reasonToIncorporate: [],
    otherReasonToIncorporate: '',
    sourceOfFund: [],
    otherSourceOfFund: '',
    businessCountries: '',
    registeredAddress: {
        type: '',
        fee: {},
        addressDetails: {
            address: '',
            postalCode: '',
            country: 'Singapore',
        },
    },
    selectedDirectors: [],
    selectedShareHolders: [],
    nationality: [],
    shareType: [],
});

export default {
    namespaced: true,
    state: getDefaultState(),

    getters: {
        getAllData(state) {
            return state;
        },

        currentOnboardingPage(state) {
            return state.currentOnboardingPage;
        },

        companyName(state) {
            return state.companyName;
        },

        getCurrentUserCompany(state) {
            return state.currentUsercompany;
        },

        companyDomain(state) {
            return state.companyDomain;
        },

        companyDomainText(state) {
            return state.companyDomainText;
        },

        fullCompanyName(state) {
            return `${state.companyName} ${state.companyDomainText}`;
        },

        financialYearEnd(state) {
            return state.financialYearEnd;
        },

        primaryBusinessActivity(state) {
            return state.primaryBusinessActivity;
        },

        secondaryBusinessActivity(state) {
            return state.secondaryBusinessActivity;
        },

        capitalCurrency(state) {
            return state.capitalCurrency;
        },

        capitalAmount(state) {
            return state.capitalAmount;
        },

        totalShare(state) {
            return state.totalShare;
        },

        reasonToIncorporate(state) {
            return state.reasonToIncorporate;
        },

        otherReasonToIncorporate(state) {
            return state.otherReasonToIncorporate;
        },

        sourceOfFund(state) {
            return state.sourceOfFund;
        },

        otherSourceOfFund(state) {
            return state.otherSourceOfFund;
        },

        businessCountries(state) {
            return state.businessCountries;
        },

        registeredAddress(state) {
            return state.registeredAddress;
        },

        newDirector(state) {
            return state.newDirector;
        },

        selectedDirectors(state) {
            return state.selectedDirectors;
        },
        selectedShareHolders(state) {
            return state.selectedShareHolders;
        },
        selectedCorporateShareholder(state) {
            if(state.selectedShareHolders === null) return [];
            return state.selectedShareHolders
                .filter(shareHolder => shareHolder.type.value === 'Corporate')
                .map(({ name }) => name);
        },

        nationality(state)
        {
            return state.nationality;
        },

        // fetch any keys from vuex
        get: state => key => state[key]
    },

    mutations: {
        CLEAR(state) {
            // eslint-disable-next-line no-param-reassign
            Object.assign(state, getDefaultState());
        },

        UPDATE_CURRENT_ONBOARDING_PAGE(state, val) {
            state.currentOnboardingPage = val;
        },

        UPDATE_COMPANY_NAME(state, val) {
            state.companyName = val;
        },

        UPDATE_CURRENT_USER_COMPANY(state, val) {
            state.currentUsercompany = val;
        },

        UPDATE_COMPANY_DOMAIN(state, val) {
            state.companyDomain = val;
        },

        UPDATE_COMPANY_DOMAIN_TEXT(state, val) {
            state.companyDomainText = val;
        },

        UPDATE_FINANCIAL_YEAR_END(state, val) {
            state.financialYearEnd = val;
        },

        UDPATE_PRIMARY_BUSINESS_ACTIVITY(state, val) {
            state.primaryBusinessActivity = val;
        },

        UPDATE_SECONDARY_BUSINESS_ACTIVITY(state, val) {
            state.secondaryBusinessActivity = val;
        },

        UPDATE_CAPITAL_CURRENCY(state, val) {
            state.capitalCurrency = val;
        },

        UPDATE_CAPITAL_AMOUNT(state, val) {
            state.capitalAmount = val;
        },

        UPDATE_TOTAL_SHARE(state, val) {
            state.totalShare = val;
        },

        UPDATE_REASON(state, val) {
            state.reasonToIncorporate = val;
        },

        UPDATE_OTHER_REASON(state, val) {
            state.otherReasonToIncorporate = val;
        },

        UPDATE_SOURCE_OF_FUND(state, val) {
            state.sourceOfFund = val;
        },

        UPDATE_OTHER_SOURCE_OF_FUND(state, val) {
            state.otherSourceOfFund = val;
        },

        UPDATE_BUSINESS_COUNTRIES(state, val) {
            state.businessCountries = val;
        },

        UPDATE_REGISTERED_ADDRESS(state, val) {
            state.registeredAddress = val;
        },

        ADD_SELECTED_DIRECTOR(state, val) {
            state.selectedDirectors.push(val);
        },

        UPDATE_SELECTED_DIRECTOR(state, data) {
            /* eslint-disable */
            state.selectedDirectors.map((beforeDirector, index) => {
                if (
                    beforeDirector.name === data.key.name &&
                    beforeDirector.email === data.key.email
                ) {
                    state.selectedDirectors[index].name = data.value.name;
                    state.selectedDirectors[index].email = data.value.email;
                }
            });
        },

        REMOVE_SELECTED_DIRECTOR(state, removeDirector) {
            /* eslint-disable */
            state.selectedDirectors.map((director, index) => {
                if (
                director.name === removeDirector.name &&
                director.email === removeDirector.email
                )
                state.selectedDirectors.splice(index, 1);
            });
        },

        ADD_SELECTED_SHAREHOLDER(state, val) {
            state.selectedShareHolders.push(val);
        },

        UPDATE_SELECTED_SHAREHOLDER(state, data) {
            /* eslint-disable */
            state.selectedShareHolders.map((beforeDirector, index) => {
                if (
                    beforeDirector.name === data.key.name &&
                    beforeDirector.email === data.key.email
                ) {
                    state.selectedShareHolders[index].name = data.value.name;
                    state.selectedShareHolders[index].email = data.value.email;
                    state.selectedShareHolders[index].type = data.value.type;
                    state.selectedShareHolders[index].share = data.value.share;
                }
            });
        },

        REMOVE_SELECTED_SHAREHOLDER(state, removeShareHolder) {
            /* eslint-disable */
            state.selectedShareHolders.map((shareholder, index) => {
                if (
                shareholder.name === removeShareHolder.name &&
                shareholder.email === removeShareHolder.email
                )
                state.selectedShareHolders.splice(index, 1);
            });
        },

        SAVE_NATIONALITY(state, value)
        {
            state.nationality = value;
        },

        SET(state, data)
        {
            state[data.key] = data.value;
        },

        REMOVE(state, key)
        {
            state[key] = null;
        }
    },

    actions: {
        storeCapitalAmountandShare({ commit }, capitalAmount) {
            commit('UPDATE_CAPITAL_AMOUNT', capitalAmount);
            commit('UPDATE_TOTAL_SHARE', capitalAmount);
        },
    },
};
