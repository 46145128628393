<template>
    <content-loader
        :width="400"
        :height="100"
        :speed="2"
        primary-color="#f3f3f3"
        secondary-color="#ecebeb"
    >
        <rect x="48" y="23" rx="2" ry="2" width="288" height="37" /> 
        <rect x="23" y="2" rx="2" ry="2" width="328" height="8" /> 
        <rect x="25" y="77" rx="2" ry="2" width="328" height="8" /> 
        <rect x="22" y="2" rx="16" ry="16" width="7" height="80" /> 
        <rect x="349" y="2" rx="16" ry="16" width="7" height="80" />
    </content-loader>
</template>

<script>
import { ContentLoader } from "vue-content-loader";

export default {
    name: "CardList",
    components: { ContentLoader },
};
</script>