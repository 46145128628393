<template>
    <span class="vector-container">
        <inline-svg
            role="img"
            :class="classes"
            :style="getStyles()"
            :src="getFullPath()"
            :aria-label="getVectorLabel()"
            :height="height"
            :width="width"
        />

        <p v-if="shouldErrorShow()" class="alert alert-danger w-100 p-2">
            {{ error }}
        </p>
    </span>
</template>

<script>

import InlineSvg from "vue-inline-svg";

export default {
    name: "Vector",

    components: {
        InlineSvg
    },

    props: {
        dataName: {
            default: "",
            required: true,
            type: String,
        },

        dataExtension: {
            default: "svg",
            required: false,
            type: String,
        },

        // the folder name of the icon
        dataType: {
            default: "icon",
            type: String,
        },

        dataClass: {
            default: "",
            required: false,
            type: String,
        },

        dataWidth: {
            default: "20px",
            required: false,
            type: String,
        },

        dataHeight: {
            default: "20px",
            required: false,
            type: String,
        },

        dataStyle: {
            default: '',
            type: String,
            required: false
        }
    },

    data() {
        return {
            error     : null,
            name      : this.dataName,
            type      : this.dataType,
            classList : this.dataClass,
            style     : this.dataStyle,
            width     : this.dataWidth,
            height    : this.dataHeight,
            extension : this.dataExtension,
        };
    },

    computed: {
        classes() 
        {
            return [
                this.getCalsses(),
                // only add dynamic colors for icons
                this.getType() === 'icons' ? 'add-dynamic-color' : '',
            ];
        }
    },

    methods: {
        shouldErrorShow() {
            return this.error && process.env.NODE_ENV === "development";
        },

        getFullPath() {
            const folder    = this.getType();
            const name      = this.getName();
            const extension = this.getExtension();

            try {
                // eslint-disable-next-line import/no-dynamic-require
                return require(`/public/${folder}/${name}${extension}`);
            } catch (e) {

                const error = `Can't find ${name}${extension} file inside /public/${folder} folder.`;
                if(process.env.NODE_ENV !== 'testing' && process.env.NODE_ENV !== 'production')
                {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
                this.error = error;
                return error;
            }
        },

        getType() {
            // icon => icons
            // illustarion => illutrations
            if (
                this.type !== "logo" &&
                this.type.search(/.*s$/) === -1
            ) {
                this.type += "s";
            }

            return this.type;
        },

        getName() {
            return this.name;
        },

        getExtension() {
            return `.${this.extension}`;
        },

        getCalsses() {
            const classes = this.classList;

            if (classes.search(",")) {
                return classes.replace(",", " ");
            }

            if (classes.search(", ")) {
                return classes.replace(", ", " ");
            }

            return classes;
        },

        getStyles() {
            let styles = `width: ${this.width}; height: ${this.height};`;
            styles += this.style;
            return styles;
        },

        getVectorLabel() {
            const label = this.fileNameToText(this.name);
            return `${this.type} of ${label}`;
        },

        fileNameToText(filename = this.name) {
            return filename.replace("-", " ").replace("_", " ");
        },
    },
};

</script>

<style>
.vector-container {
    display: inline-block;
}

.add-dynamic-color path {
    fill: currentColor;
}
</style>