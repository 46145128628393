import Vue from 'vue';
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue';
import Vector from './views/components/utils/Vector.vue';
import Action from './views/components/utils/Action.vue';
import Loader from './views/components/utils/Loader.vue';
import NormalLoader from './views/components/utils/NormalLoader.vue';

Vue.component(Vector.name, Vector);
Vue.component(Loader.name, Loader);
Vue.component(Action.name, Action);
Vue.component(NormalLoader.name, NormalLoader);
Vue.component(FeatherIcon.name, FeatherIcon);
