import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLs from "secure-ls";

// Modules
import app from "./app";
import authStore from "./app/auth";
import cartStore from "./app/cart";
import appConfig from "./app-config";
import companyStore from "./app/company";
import verticalMenu from "./vertical-menu";
import ecommerceStoreModule from "@/views/apps/e-commerce/eCommerceStoreModule";

Vue.use(Vuex);
const ls = new SecureLs({ isCompression: false });

const persistedState = createPersistedState({
    key: "data",
    paths: ["company", "campaign","cart", 'formation'],
    storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key),
    },
});

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        auth: authStore,
        cart: cartStore,
        company: companyStore,
        "app-ecommerce": ecommerceStoreModule,
    },
    plugins: [persistedState],
    strict: process.env.DEV,
});
